<template>
	<div class="container-fluid p-0">
		<!-- main content -->
		<!-- 编辑区域 -->
		<div class="m-md-3">
			<div class="row my-3">
				<div class="col-md-10 offset-md-1">
					<div class="card no-b">
						<div class="card-body">
							<h5 class="card-title">系统设置</h5>

							<div>

								<div class="box-body no-padding">
									
									<table class="table">
										<tbody>

											<tr>
												<td style="width: 100px;">广告图片</td>
												<td>
													<div class="row">
											
														<div class="col-md-4" v-for="(item, index) in adList"
															v-bind:key="index">
															<!-- <h6>{{item.name}},{{(item.size/1024).toFixed(0)}}KB </h6> -->
																<img  :src='item.url'/>
															<!-- {{item.url}} -->
															{{item.link}}
														</div>
											
													</div>
												</td>
												<td>
													<div class="float-right">
														<button @click="editAdSetting" type="button"
															class="btn btn-dark btn-sm"> 设置 </button>
													</div>
												</td>
											</tr>
											
											<tr>
												<td style="width: 120px;">转赠</td>
												<td>
													<!-- {{share_mode==1?'允许':'不允许'}} -->
													<span :class="{'badge badge-pill badge-success':share_mode==1,'badge badge-pill badge-dark':share_mode==0}">{{share_mode==1?'允许':'禁止'}}</span>
												</td>
												<td>
													<div class="float-right mt-3">
														<div class="material-switch float-right">
															<input 
															@change="onchangeShareMode"
															v-model="share_mode" value="1" 
															id="sm" name="sm" type="checkbox">
															<label for="sm" class="bg-primary"></label>
														</div>
														
													</div>
												</td>
											</tr>
											
										</tbody>
									</table>
								</div>

								
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- //编辑区域 -->
		<!-- //main content -->

		<!-- modal -->

		<!-- 广告设置 -->
		<div class="modal fade" id="adModal" tabindex="-1" aria-labelledby=""
			aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="">广告设置</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<!-- <input type="file" multiple @change="getFile($event)"> -->
						
						<div class="m-3">
							<div class="form-group">
								<label class="font-weight-bold">广告图片(最多3张)</label>
								<div class="s-12 pb-2 text-danger">图片格式为jpg,png，尺寸为1084x400像素</div>
								<vue-upload-imgs compress :before-read="beforeRead" :after-read="afterRead"
									:before-remove="beforeRemove" :limit="limit" :type="type" @preview="preview"
									@exceed="exceed" @oversize="oversize" :multiple="true" v-model="files" >
								</vue-upload-imgs>
								
							</div>

						</div>
						
						<div class="m-3">
							<div class="form-group">
								<label class="font-weight-bold">广告图片链接(选填)</label>
								
								<template v-for="(item, index) in files">
									<input  
									:id="index"
									v-bind:key="index" 
									@keyup.enter="onInput" @input="onInput($event)"
									type="text" :placeholder="'请输入广告图片'+(index+1)+'链接'" class="form-control mb-3"/>
								</template>
							</div>
						
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveAdSetting" :disabled="disabled" type="button" class="btn btn-primary">
							保存 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 图片预览 -->
		<div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">图片预览</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body center">

						<div class="m-3">

							<img :src="previewIMG" class="preview-img">

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal"> 关闭 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- //modal -->
	</div>
</template>

<script>
	

	import VueUploadImgs from '../components/VueUploadImgs.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data() {
			return {
				title: '帐号与安全',

				token: '',
				dept_id: '',
				

				disabled: false,
				adList : [],
				ossUrl: '',

				//图片上传
				files: [],
				maxSize: 1024 * 10, // 10 KB
				previewIMG: null,
				limit: 3,
				isPreview: false,
				type: 2, // 0 预览模式 1 列表模式 2 预览模式 + 上传按钮
				
				adFiles: [],//所有的暂存文件，也就是最后提交的文件
				links: ['','',''],//图片链接
				
				share_mode: 0,
			}
		},
		
		computed: {
			// receiptCloudPrintSetting: function () {
			// 	let self = this;
			// 	return this.tempCloudPrintSetting.filter(function (item) {
			// 		return item.print_type == 'receipt';
			// 	})
			// },
		},

		components: {
			// DatePicker,
			VueUploadImgs
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			
			self.ossUrl = this.GLOBAL.baseImgURI;
			
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			self.initData();
		},
		methods: {
			initData() {
				let self = this;

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getDeptSetting', {
						params: {
							token: self.token,
							dept_id: self.dept_id, //可以不传，从后端TOKEN取得
							// setting_key: 'BUSINESS_HOURS_SETTING',
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log('--- data ->', data.data);
						if (data.code == 200) {
							if (data.data.ad_setting!=undefined) {
								let adImgList = data.data.ad_setting;
								self.adList = [];
								for (let item of adImgList) {
									//2024加链接兼容处理，旧的是['imgfile','',''],新的是[{img:xxx,ulr:xxx},{},{}]
									console.log("---- 广告对象 ->",item.img)
									if(item.img == undefined){//旧的
										let adObj = {
											url: self.ossUrl + item , //'?x-oss-process=style/100x100',
											link: '',
										}
										self.adList.push(adObj );
									}
									
									if(item.img!= undefined){//新的
										let adObj = {
											url: self.ossUrl + item.img , //'?x-oss-process=style/100x100',
											link: item.url,
										}
										self.adList.push(adObj );
									}
									
								}
								console.error('--- self.adList ->', self.adList);
							} else{
								//TODO
							}
							
							if (data.data.share_setting!=undefined) {
								let shareSetting = data.data.share_setting;
								self.share_mode = shareSetting.share_mode || 0
								console.error('--- self.share_mode ->', self.share_mode);
							} else{
								//TODO
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							location.href = "index.html";
						} else {
							alert(data.message)
						}
					});
				//END

			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				// self.init(() => {
				self.initData();
				// });
			},

			//图片上传
			oversize(file) {
				console.log('oversize')
				console.log('filesize:' + file.size / 1024 + 'KB')
			},
			
			exceed() {
				alert(`只能上传${this.limit}张图片`)
				// self.$toasted.error( '只能上传${this.limit}张图片', {
				// 	position: 'top-center',
				// 	duration: '2000',
				// })
			},
			

			preview(index, file) {
				let url = file
					.url; //<img class="preview-img" src="https://oss-pk-test.oss-cn-shenzhen.aliyuncs.com/51118960.jpg?x-oss-process=style/sm-150x150&amp;0.836985165951373">
				let newurl = url.indexOf('aliyuncs.com') != -1 ? url.replace('sm-150x150', 'w480') : url;

				this.previewIMG = newurl;
				this.isPreview = true


				$('#previewModal').modal('show');
			},
			
			beforeRemove(index, file) {
				console.log(index, file)
				let self = this;
				// this.tempOutletInfo["img_file"] = null;
			
				// //远程图片删除标记
				// if (this.tempOutletInfo.logo != '') {
				// 	this.tempOutletInfo['del_flag'] = 1;
				// }
				
				self.adFiles.splice(index, 1);
				
				console.log('before-remove, self.adFiles ->',self.adFiles)
			
				return true
			},

			beforeRead(files) {
				console.log('before-read')
				let self = this;
				
				for (let i = 0, len = files.length; i < len; i++) {
					const file = files[i]
					if (file.type != 'image/jpeg' && file.type != 'image/png') {
						// alert('只能上传jpg和png格式的图片')
						self.$toasted.error( '只能上传jpg和png格式的图片', {
							position: 'top-center',
							duration: '2000',
						})
						return false
					}
					
					self.adFiles.push(file);
				}

				console.log('--- files  -> ', files)
				
				// this.tempOutletInfo["img_file"] = files[0];
				// //远程图片删除标记
				// this.tempOutletInfo['del_flag'] = 0; //只有在上传，都不能标记删除
				
				return true
			},
			
			afterRead(files) {
				let self = this;
				
				console.log('after-read, files ->',files)
				console.log('after-read, self.adFiles ->',self.adFiles)
			},
			

			editAdSetting() {
				let self = this;

				self.files = []; //重置 //self.adList; 
				
				self.adFiles = [];//所有上传文件暂存
				
				$('#adModal').modal('show');

			},
			
			saveAdSetting() {
				let self = this;
				
				if(self.dept_id==0) return;

				self.disabled = true;
				
				// 组装数据
				let postData = {};

				postData['token'] = self.token;
				postData["dept_id"] = self.dept_id;
				postData["links"] = JSON.stringify(self.links);
				
				let formData = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					formData.append(key, postData[key]);
				}
				
				console.error('--- 最终上传的图片文件 adFiles ->',self.adFiles)
				
				// formData重复的往一个值添加数据并不会被覆盖掉，可以全部接收到，可以通过formData.getAll('files')来查看所有插入的数据
				for (let i = 0;i < self.adFiles.length;i++) {
					console.error(i + ':' + self.adFiles[i]);
					formData.append('files', self.adFiles[i])//必须这样，才可以提交多个图片文件
				}

				// return;
				
				// let headers = {
				//     'Content-Type': 'multipart/form-data'
				// }

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'saveAdSetting',
						formData
						, {
							headers: {
								'Content-Type': 'multipart/form-data'
							},
						}
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error( '保存失败', {
							position: 'top-center',
							duration: '2000',
						})
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功');
							self.$toasted.success( '保存成功', {
								position: 'top-center',
								duration: '2000',
							})
							
							//重载处理
							self.initData();

							$('#adModal').modal('hide');

						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							location.href = "index.html";
						} else {
							alert(data.message)
						}
					});
				//END

			},
			
			getFile(event) {
				this.adFiles = event.target.files;
				console.error('--- adFiles in getFile ->',this.adFiles);
			},
			
			onchangeShareMode(e){
				let self = this;
				
				if(self.dept_id==0) return;

				self.disabled = true;
				
				// 组装数据
				let postData = {};

				postData['token'] = self.token;
				postData["dept_id"] = self.dept_id;
				postData["share_mode"] = self.share_mode?1:0;
				
				let formData = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					formData.append(key, postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'saveShareSetting',
						formData
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error( '保存失败', {
							position: 'top-center',
							duration: '2000',
						})
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功');
							self.$toasted.success( '保存成功', {
								position: 'top-center',
								duration: '2000',
							})
							
							//重载处理
							// self.initData();

							// $('#adModal').modal('hide');

						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							location.href = "index.html";
						} else {
							alert(data.message)
						}
					});
				//END

			},
			
			onInput(e){
				console.log(e.target.id,e.currentTarget.value)
				
				let self = this;
				
				let index = e.target.id;
				let value = e.currentTarget.value;
				
				self.links[index] = value;
				console.log('--- self.links ->',self.links)
			}

			

		},
	}
</script>

<style>
</style>
